import styled from '@emotion/styled'
import {breakpoints} from '../../../utils/styles'

export const CartItem = styled.li`
  width: 100%;
  height: 155px;
  padding-top: 23px;
  padding-bottom: 23px;
  @media (max-width: ${breakpoints.l}px){
    height: 100%;
    padding-left: 50%;
    transform: translateX(-20%);
  }
  @media (max-width: ${breakpoints.m}px){
	padding-left: 0;
	transform: translateX(0);
	text-align: center;
  }
`
export const Product = styled.div`
  text-align: left;
  width: 40%;
  height: 100%;
  @media (max-width: ${breakpoints.m}px){
	  width: 100%;
  }
`
export const ProductImage = styled.div`
  height: 100%;
  width: 119px;
  & img {
    max-width: 100%;
  }
  @media (max-width: ${breakpoints.m}px){
	margin-left: 50%;
	transform: translateX(-50%);
  }
`
export const ProductName = styled.div`
    & p {
    padding-left: 30px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #414141;
    font-weight: 600;
    transition: all 200ms ease;
  }
  & p:hover {
    color: #bbe432;
  }
  @media (max-width: ${breakpoints.m}px){
	margin-left: 50%;
	transform: translateX(-50%);
	& p {
		padding-left: 0;
	}
  }
`
export const ProductColor = styled.div`
  width: 20%;
  margin-left: 4%;
  font-size: 16px;
  font-weight: 700;
  color: #414141;
  @media (max-width: ${breakpoints.m}px){
	  width: 100%;
	  margin-left: 0;
  }
`
export const ProductPrice = styled.div`
  width: 14%;
  font-size: 16px;
  font-weight: 700;
  color: #414141;
  @media (max-width: ${breakpoints.m}px){
	  width: 100%;
  }
`
export const ProductButton = styled.div`
  width: 16%;
  margin-left: 6%;
  @media (max-width: ${breakpoints.m}px){
	width: 100%;
	margin-left: 0;
  }
`


