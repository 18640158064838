import styled from '@emotion/styled'
import {breakpoints} from '~/utils/styles'

export const CartSection = styled.div`
  width: 100%;
  background: #FFFFFF;
  padding-top: 83px;
  padding-bottom: 104px;
  @media (max-width: ${breakpoints.l}px){
    padding-top: 0;
  }
`
export const CartBar = styled.div`
  width: 100%;
  background: #edf4f6;

  @media (max-width: ${breakpoints.l}px){
    display: none;
  }
`
export const CartBarList = styled.ul`
  display: block;
  height: 48px;
`
export const CartBarListLi = styled.li`
  font-size: 12px;
  font-weight: 500;
  color: #414141;
  text-align: center;
  &:first-child {
    padding-left: 30px;
    text-align: left;
    width: 37%
  }
  &:nth-child(2){
    width: 21%;
  }
  &:nth-child(3){
    width: 21%;
    margin-right: 3%
  }
  &:nth-child(4){
    width: 21%;
  }
 
  @media (max-width: ${breakpoints.l}px){
    &:first-child, &:nth-child(2), 
    &:nth-child(3), &:nth-child(4) {
      width: 100% !important;
    }
  }

`
export const CartItems = styled.div`
  padding-bottom: 4px;
  border-bottom: solid 1px #b0b2b3;
`
export const CartItemsList = styled.ul`
  display: block;
`
export const CartExtra = styled.div`
  padding-top: 80px;
  width: 80%;
  margin: 0 auto;
  @media (max-width: ${breakpoints.xl}px){
    padding-left: 0px;
    margin-top: 80px;
  }
  @media (max-width: ${breakpoints.s}px){
    width: 100%;
  }
`
export const CartExtraContent = styled.div`
  background: #edf4f6;
  padding: 78px 80px 82px 94px;
  @media (max-width: ${breakpoints.m}px){
    padding-left: 60px;
  }
  @media (max-width: ${breakpoints.s}px){
    padding-left: 30px;
		padding-right: 30px;
  }
`
export const CartExtraTitle = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #414141;
  line-height: 0.75;
`
export const CartExtraTotalList = styled.ul`
  & li {
    position: relative;
  }
  & li:not(:last-child){
    margin-bottom: 27px;
  }
  & li:last-child::before {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 76px);
    height: 41px;
    background: #fff;
    content: '';
    z-index: 0;
  }
  @media (max-width: ${breakpoints.s}px){
    &:last-child::before {
      width: calc(100% + 36px);
    }
  }
` 
export const CartExtraTotalTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #414141;
`
export const CartExtraTotalValue = styled.div`
  font-size: 14px;
	font-weight: 600;
	color: #414141;
`
export const Checkout = styled.div`
  width: 100%;
  height: 60px;
  text-align: center;
  margin-top: 86px;
`