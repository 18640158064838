import React from 'react'
import SEO from '~/components/seo'
import Cart from '~/components/Cart'

const CartPage = () => (
  <>
	<SEO title="Your Cart" />
    <Cart />
  </>
)

export default CartPage
