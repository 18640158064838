import React, { useContext } from 'react'
import StoreContext from '~/context/StoreContext'
import {CartItem, Product, ProductName, ProductImage, 
    ProductColor, ProductPrice, ProductButton,
} from './styles'

const LineItem = props => {
  const { line_item } = props
  const {
    removeLineItem,
    store: { client, checkout },
  } = useContext(StoreContext)
  
  const variantImage = line_item.variant.image ? (
    <img
      src={line_item.variant.image.src}
      alt={`${line_item.title} product shot`}
      height="125px"
    />
  ) : null

  const selectedOptions = line_item.variant.selectedOptions
    ? line_item.variant.selectedOptions.map(
        option => `${option.value} `
      )
    : null


  const handleRemove = () => {
    removeLineItem(client, checkout.id, line_item.id)
  }
  
  const price = line_item.variant.price
  const quan = line_item.quantity
  const setPrice = price*quan
  

  return (
    <CartItem className={`d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-start`}>
      <Product className={`d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-start`}>
        <ProductImage>
          {variantImage}
        </ProductImage>
        <ProductName>
          <p>{line_item ? line_item.title : "Nothing to see here"} (<span>{quan}</span>)</p>
        </ProductName>
      </Product>
      <ProductColor>
        {selectedOptions}
      </ProductColor>
      <ProductPrice>
        ${setPrice}
      </ProductPrice>
      <ProductButton>
        <button className={`btn btn-secondary`} onClick={handleRemove}>Remove</button>
      </ProductButton>
  </CartItem>
  )
}

export default LineItem
