import React, { useContext } from 'react'
import StoreContext from '~/context/StoreContext'
import LineItem from './LineItem'
import styled from '@emotion/styled'
import {CartSection, CartBar, CartBarList,
    CartBarListLi, CartItems, CartItemsList, CartExtra, 
    CartExtraTitle, CartExtraContent, CartExtraTotalList,
    CartExtraTotalTitle, CartExtraTotalValue, Checkout
} from './styles'
import {SectionContainer} from '~/utils/styles'

const Wrapper = styled.div`
  margin: 0 auto;
`

const Cart = () => {
  const {
    store: { checkout },
    
  } = useContext(StoreContext)

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  const line_items = checkout.lineItems.map(line_item => {
    return <LineItem key={line_item.id.toString()} line_item={line_item} />
  })

  return (
    <Wrapper>
      <CartSection>
        <SectionContainer>
          <div className={`container`}>
            <div className={`row`}>
              <div className={`col`}>
                <div>
                  <CartBar>
                    <CartBarList className={`item_list d-flex flex-row align-items-center justify-content-start`}>
                      <CartBarListLi>Product</CartBarListLi>
                      <CartBarListLi>Color</CartBarListLi>
                      <CartBarListLi>Price</CartBarListLi>
                      <CartBarListLi>x</CartBarListLi>
                    </CartBarList>
                  </CartBar>
                </div>
              </div>
            </div>
          </div>
          <CartItems>
            <CartItemsList>
              {line_items}
            </CartItemsList>
          </CartItems>
        </SectionContainer>
        <SectionContainer className={`cart_extra_container`}>
          <div className={`container`}>
            <div className={`row`}>
              <CartExtra>
                <CartExtraContent>
                  <CartExtraTitle>Preview Cart Total</CartExtraTitle>
                  <CartExtraTotalList>
                    <li className={`d-flex flex-row align-items-center justify-content-start`}>
                      <CartExtraTotalTitle>Subtotal</CartExtraTotalTitle>
                      <CartExtraTotalValue className={`ml-auto`}>${checkout.subtotalPrice}</CartExtraTotalValue>
                    </li>
                    <li className={`d-flex flex-row align-items-center justify-content-start`}>
                      <CartExtraTotalTitle>Taxes</CartExtraTotalTitle>
                      <CartExtraTotalValue className={`ml-auto`}>tbd</CartExtraTotalValue>
                    </li>
                    <li className={`d-flex flex-row align-items-center justify-content-start`}>
                      <CartExtraTotalTitle>Total</CartExtraTotalTitle>
                      <CartExtraTotalValue className={`ml-auto`}>${checkout.totalPrice}</CartExtraTotalValue>
                    </li>
                  </CartExtraTotalList>
                  <Checkout>
                    <button className={`btn btn-secondary`} onClick={handleCheckout} disabled={checkout.lineItems.length === 0}>Continue</button>
                  </Checkout>
                </CartExtraContent>
              </CartExtra>
            </div>
          </div>  
        </SectionContainer>
      </CartSection>
    </Wrapper>
  )
}

export default Cart
